import React from "react";

import Layout from "components/layout";
import SEO from "components/seo";

const NotFoundPage: React.FC = () => (
  <>
    <SEO title="Page Not found" />

    <Layout>
      <div className="flex justify-center items-center w-full p-6">
        <div>
          <h1 className="text-2xl">Page Not Found</h1>
          <p className="mt-4">Page doesn&apos;t exist...</p>
        </div>
      </div>
    </Layout>
  </>
);

export default NotFoundPage;
